import React from 'react';
import Modal from '../../../components/Modal';
import Quote from '../../../components/Quote';

const media = {
  secTitle: 'Control of One’s Personal Narrative',
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/lhAieKqMk7g',
  },
};

export default () => (
  <Modal closeTo="Learned" media={media}>
    <p className="Title--2 Title--c">
      Communities of color, low-income individuals, and those living in the
      margins have seldom been in control of telling their own stories.
    </p>
    <p>
      When white people and mainstream media control the overriding narrative,
      black people are disenfranchised. In both the interviews and focus groups,
      common narratives seemed to drive the perception of the “other.” People’s
      lives are impacted due to these perceptions socially, economically,
      educationally, collectively, and individually.
    </p>
    <p>
      As an example, perceptions of black criminality were a recurring theme in
      our discussions. Dominant media narratives based on perceptions of
      marginalized groups are often inaccurate, one-sided and damaging. For
      example, Color of Change reports note that news and opinion media in the
      U.S. are almost 1.5 times more likely to represent a white family as an
      illustration of social stability than a black family.{' '}
    </p>
    <p>
      Many people we talked to expressed frustration that the challenges facing
      their communities continue to go unaddressed or unnoticed by those in
      positions of power, and even more so by liberal media outlets. A report by
      the W.K. Kellogg Foundation noted that “while creating more understanding
      than conservative commentary, traditional liberal narratives focusing on
      the role of race are not as successful in shifting opinion.”
    </p>
    <Quote
      quote="We want safe places. We want healthy spaces. We want the same things. And so, I think so often that stereotype that has been perpetuated, has done a disservice to African Americans and to people of color, and it is our responsibility saying, how do we tell that story? How do we tell that narrative and not depend on someone else to tell that story and that narrative for us?"
      person="Black male, 50"
      title="Richmond"
    />
    <Quote
      quote="I think there’s just widespread sense that on the side of white, wealthy, particularly older folks, that Black people are not to be trusted, that they’re not willing to work hard enough, that they’re incompetent."
      person="White male, 36"
      title="Jackson"
    />
    <Quote
      quote="One of the biggest impacts of the real crime that exists in the city is that it has created some imaginary boundaries for people. Some of those people who are employees of the organizations or entities who control the systems in the state, they have some legitimate fears. But they also have some imagined fears, based on the perception of crime."
      person="Black male, 38"
      title="Jackson"
    />
    <Quote
      quote="The vision of particularly the white population that allows them to feel good about themselves and sleep at night is in part creating this myth around Black criminality. And that has been a myth that has been built since the Civil War, right?"
      person="White male, 41"
      title="Jackson"
    />
    <Quote
      quote="If young black and poor children don’t see anything about themselves on television and video games except as aggressor or violent criminals, what does do to them internally?"
      person="Black woman, 67"
      title="Jackson"
    />
  </Modal>
);
